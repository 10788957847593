<template>
  <div class="table_wrapper">
    <v-card class="p-3">
      <v-card-title
        >Languages Manager
        <v-spacer></v-spacer>
        <v-btn color="primary" v-on:click="(dialog2 = true), (is_edit = false)"
          >New</v-btn
        ></v-card-title
      >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Translations
              </th>
              <th class="text-left">
                Created at
              </th>
              <th class="text-left">
                Updated at
              </th>
              <th class="text-left">
                Edit
              </th>
              <th class="text-left">
                Delete
              </th>
            </tr>
          </thead>
          <tbody v-if="data">
            <tr v-for="(item, index) in data" :key="index">
              <td>
                {{ item.name }}
              </td>
              <td>
                <v-btn
                  small
                  elevation="0"
                  :to="'/languages/edit/' + item.id"
                  dark
                  color="warning"
                >
                  <v-icon small>mdi-pencil</v-icon>
                  Translations
                </v-btn>
              </td>
              <td>
                {{ moment(item.created_at).fromNow() }}
              </td>
              <td>
                {{ moment(item.updated_at).fromNow() }}
              </td>
              <td>
                <v-btn
                  small
                  elevation="0"
                  v-on:click="
                    (dialog2 = true), (form2 = item), (is_edit = true)
                  "
                >
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
              </td>
              <td>
                <v-btn
                  small
                  color="red"
                  dark
                  elevation="0"
                  v-on:click="deleteItem(item)"
                >
                  <v-icon small>mdi-close</v-icon>
                  Delete
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-dialog v-model="dialog2" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Language</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid2" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ID*"
                    :rules="rulesID"
                    max="2"
                    v-model="form2.id"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Name*"
                    :rules="rulesName"
                    v-model="form2.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    label="Is Enable"
                    v-model="form2.is_enable"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-switch label="Is RTL" v-model="form2.is_rtl"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" :disabled="!valid2" text @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="loading" persistent>
      <v-sheet class="text-center" height="200px">
        <div class="pt-5">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <h5 class="mt-3">Loading, Please wait..</h5>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Confirm from "../Components/Confirm.vue";
import RulesClass from "../../services/rules";
import moment from "moment";
const rulesClass = new RulesClass();

export default {
  name: "Languages",
  data() {
    return {
      moment: moment,
      is_edit: false,
      valid2: false,
      loading: true,
      data: [],
      token: localStorage.getItem("authToken"),
      page: 1,
      per_page: 10,
      dialog2: false,
      form2: {
        id: "",
        name: "",
        is_enable: false,
        is_rtl: false,
      },
    };
  },
  components: {
    Confirm: Confirm,
  },
  methods: {
    save() {
      this.loading = true;
      if (this.is_edit) {
        axios
          .put(
            process.env.VUE_APP_API_URL +
              "language/" +
              this.form2.id +
              "?api_token=" +
              this.token,
            this.form2
          )
          .then((res) => {
            this.data.unshift(res.data.data);
            this.loading = false;
            this.dialog2 = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.dialog2 = false;
          });
      } else {
        axios
          .post(
            process.env.VUE_APP_API_URL + "language?api_token=" + this.token,
            this.form2
          )
          .then((res) => {
            this.data.unshift(res.data.data);
            this.loading = false;
            this.dialog2 = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.dialog2 = false;
          });
      }
    },
    updateItem: function(item) {
      this.dialog2 = true;
      this.form2 = item;
    },
    deleteItem: function(item) {
      this.$refs.confirm
        .open("Delete", "Are you sure you?", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.loading = true;
            if (confirm) {
              axios
                .get(
                  process.env.VUE_APP_API_URL +
                    "language/language-line-delete/" +
                    item.id +
                    "?api_token=" +
                    this.token
                )
                .then((res) => {
                  this.loading = false;
                  this.dialog2 = false;
                  this.getList();
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        });
    },
    getList() {
      const url =
        process.env.VUE_APP_API_URL +
        "language/filter?page=" +
        this.page +
        "&per_page=" +
        this.per_page +
        "&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          this.data = res.data.data.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    rulesID() {
      return rulesClass.req({
        allowSpaces: false,
        min: 2,
        max: 2,
      });
    },
    rulesName() {
      return rulesClass.req({
        allowSpaces: true,
        min: 3,
        max: 35,
      });
    },
  },
};
</script>
